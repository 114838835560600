body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@media (min-width: 320px) and (max-width: 480px) {
  .couverture-title {
    padding: 0 !important;
  }
  .page-couverture-actions-container {
    flex-direction: column;
  }
  .crypta-btn-success {
    letter-spacing: 0 !important;
  }
  .subscribe-newsletters-btn-container,
  .play-video-btn-container {
    margin: 15px !important;
  }
  .crypta-btn-default {
    text-align: center;
  }
  .play-video-btn-container {
    display: flex;
    justify-content: center;
  }
}

@media screen and (min-width: 1900px) {
  .couverture-title {
    font-size: 100px !important;
    line-height: 110px !important;
  }
  .coming-soon-textual {
    font-size: 55px !important;
  }
  .page-couverture-title {
    margin: 30px 15px !important;
  }
  .crypta-btn-success {
    padding: 35px 40px !important;
    font-size: 30px !important;
  }
  .crypta-btn-default {
    padding: 35px 40px !important;
    font-size: 40px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }
  .play-video-icon {
    width: 100px !important;
    height: 100px !important;
    display: flex !important;
    padding: 25px !important;
  }
  .subscribe-newsletters-btn-container {
    display: flex !important;
    align-items: center !important;
  }
}
.custom-pagination-component {
  padding-left: 3%;
  padding-right: 2%;
}
.crypta-tabs-container .tabs-container {
  padding-left: 4%;
  padding-right: 4%;
  padding-bottom: 2%;
}
.crypta-tabs-container {
  margin-left: 1%;
}
.crypta-locataire-histo-row {
  margin-left: 1%;
}
.msg-user-infos-container {
  padding-left: 15px;
}
.breadcrumb-container.container-fluid {
  padding-left: 8% !important;
  padding-right: 8% !important;
  background: #ebebeb;
}
.breadcrumb-page-title-content-body {
  padding: 20px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.page-title {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: clamp(28px, 3vw, 43px);
  line-height: clamp(34px, 3vw, 59px);
  color: #000000;
  padding: 20px 0;
}
.breadcrumb-row {
  display: flex;
  align-items: center;
}
.base-home-container {
  display: flex;
  align-items: center;
  /* width: 100%; */
  justify-content: space-between;
}
.breadcrumb-page-title-content-body {
  padding: 20px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.home-icon {
  font-size: 25px;
  color: #08bb90;
}
.home-label-container .home-label {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: #000000;
}
.arrow-icon {
  font-size: 24px;
  color: #000;
}
.second-level-breadcrumb-container .breadcrumb-second-level-label {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: #000000;
}
.home-breadcrumb-link {
  text-decoration: none !important;
}
.img-about-us-kiwu {
  width: 90%;
}
.kivu-about-us-paragraph,
.kivu-about-us-paragraph p {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 34px;
  text-align: justify;
  letter-spacing: 0.01em;
  color: #fff;
}
.content-section-page-about-us {
  padding-top: 100px 8%;
}

.react-tel-input.auth-form-control .form-control {
  background: #f2f2f2 !important;
  border: 0px solid #cacaca !important;
  height: 55px !important;
}

.btn-candidate {
  border: 1px solid #1468cc !important;
  color: #1468cc !important;
}

.text-info-transaction-value,
.text-info-transaction-value p,
.text-info-transaction-value li {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600 !important;
  font-size: 14px;
  line-height: 21px;
  color: rgba(0, 0, 0, 0.7) !important;
}

.text-info-transaction-libelle {
  font-family: "Poppins" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 15px !important;
  line-height: 100% !important;
  color: #08bb90 !important;
}
.item-c {
  display: flex;
  align-items: center;
}
.nowrap {
  white-space: nowrap;
}

.title-login-page {
  color: #000;
  text-align: center;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  font-family: "Nunito";
}

.cgu-content p{
  color: #000;
  /* text-align: justify; */
  font-family: "Nunito";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 180%;
  text-wrap: balance;
}